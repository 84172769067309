/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  
  getPrescription: state => (prescriptioninvoiceId) => state.prescriptioninvoice.find((prescriptioninvoice) => prescriptioninvoice.id === prescriptioninvoiceId),

  getTreatment: state => (treatmentinvoiceId) => state.treatmentinvoice.find((treatmentinvoice) => treatmentinvoice.id === treatmentinvoiceId),

  getSickleave: state => (sickleaveinvoiceId) => state.sickleaveinvoice.find((sickleaveinvoice) => sickleaveinvoice.id === sickleaveinvoiceId)

}
