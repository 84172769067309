/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  prescriptioninvoice :[],
  treatmentinvoice :[],
  sickleaveinvoice :[],
  facture :[],
  items: 0,
  produits:[]

}
