/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

  addprescriprion_invoices ({ commit }, data) {
    commit('ADD_PRESCRITION', data)
  },
  addtreatment_invoices ({ commit }, data) {
    commit('ADD_TREATMENT', data)
  },
  addsickLeave_invoices ({ commit }, data) {
    commit('ADD_SICKLEAVE', data)
  },
  addBill_invoices ({ commit }, data) {
    commit('ADD_BILL', data)
  },
 
  fetchDataListItems ({ commit}, data) {

    commit('SET_PRODUCT', data)
       
  },

  addItem ({ commit}, product) {
    commit('ADD_ITEM_PRODUIT', Object.assign(product, this.state.items))
  },

  removeItem ({ commit }, productId) {
    commit('REMOVE_ITEM_PRODUIT', productId)
  },

  updateItem ({ commit}, product) {
    commit('UPDATE_ITEM_PRODUIT', product)
  },
  initItem ({ commit }) {
    commit('INTILISATION_ITEM_PRODUIT')
  },

  addItemUpdate ({ commit }, product) {
    commit('ADD_ITEM_UPDATE', product)
  }
}
