/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

  ADD_PRESCRITION (state, prescriptioninvoice) {
    state.prescriptioninvoice = prescriptioninvoice
  },

  ADD_TREATMENT (state, treatmentinvoice) {
    state.treatmentinvoice = treatmentinvoice
  },


  ADD_BILL (state, billinvoice) {
    state.facture = billinvoice
  },

  ADD_SICKLEAVE (state, sickleaveinvoice) {
    state.sickleaveinvoice = sickleaveinvoice
  },

  SET_PRODUCT (state, produit) {
    state.produits = produit
  },

  ADD_ITEM_PRODUIT (state, produit) {
    state.produits.unshift(produit)
    state.items++
  },

  REMOVE_ITEM_PRODUIT (state, produitId) {
    const ItemIndex = state.produits.findIndex((p) => p.id === produitId)
    state.produits.splice(ItemIndex, 1)
  },
  UPDATE_ITEM_PRODUIT (state, produit) {
    const itemIndex = state.produits.findIndex((p) => p.id === produit.id)
    Object.assign(state.produits[itemIndex], produit)
  },
  INTILISATION_ITEM_PRODUIT (state) {
    state.produits = []
    state.items = 0
  },
  ADD_ITEM_UPDATE (state, produit) {
    state.produits.unshift(produit)
    state.items++

  }

}
