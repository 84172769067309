import { render, staticRenderFns } from "./DataListFacture_impayé.vue?vue&type=template&id=438d008b&scoped=true&"
import script from "./DataListFacture_impayé.vue?vue&type=script&lang=js&"
export * from "./DataListFacture_impayé.vue?vue&type=script&lang=js&"
import style0 from "./DataListFacture_impayé.vue?vue&type=style&index=0&id=438d008b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438d008b",
  null
  
)

export default component.exports