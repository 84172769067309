<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("PAYER_UNE") }} {{$t("BILL")}}  </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
            <div>
                <vs-checkbox disabled v-if="insured===false" v-model="insured" class="mb-3">
                <b style="color: #ff6141" >Patient n'est assuré.</b>
                </vs-checkbox>
                <vs-checkbox disabled v-if="insured===true" v-model="insured" class="mb-3">
                <b style="color: hsl(128, 74%, 32%)" > Patient est assuré.</b>
                </vs-checkbox>
            </div>
        
        <p class="pt-4">{{ $t("patient") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
          readonly
          data-vv-validate-on="blur"
          name="patient"
          v-model="patient"
          class="w-full" />

          <p class="pt-4">{{ $t("refFacture") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            readonly
            data-vv-validate-on="blur"
            name="treatment"
            v-model="name"
            class="w-full" />
        
          <p class="pt-4">{{ $t("totalAmount") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            readonly
            data-vv-validate-on="blur"
            name="totalAmount"
            v-model="totalAmount"
            class="w-full" />
          
            <div class="pt-4">
              <vs-checkbox v-if="insured===false" v-model="reduction" class="mb-3">
                <b style="color: #ff6141" >Voulez-vous appliquer une réduction?</b>
                </vs-checkbox>
                <vs-checkbox  v-if="insured===true" v-model="reduction" class="mb-3">
                <b style="color: hsl(128, 74%, 32%)" > Voulez-vous appliquer une réduction?</b>
                </vs-checkbox>
            </div>
             <!-- INSURED TRUE -->
          <p class="pt-4"  v-if="insured===true && reduction === true" >{{ $t("Type_reduction") }}<b style="color: #ff6141" >*</b> </p>         
            <v-select
            v-if="insured===true && reduction === true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="Type_reduction"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="Type_reduction"
            :options="TypeReduction">
          </v-select>
          <span  v-if="insured===true && reduction === true" class="text-danger text-sm" v-show="errors.has('Type_reduction')">{{$t("Type_reduction_error")}}</span>
           

          <p class="pt-4" v-if="insured===true && Type_reduction ==='rate'">{{ $t("rate") }}%<b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            v-if="insured===true && reduction === true && Type_reduction ==='rate'"
            min="0" 
            :max="100"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="rate"
            v-model="rate"
            class="w-full" />
          <span  v-if="insured===true && reduction === true && Type_reduction ==='rate'" class="text-danger text-sm" v-show="errors.has('rate')">{{$t("rate_error")}}</span>
          <span class="text-danger text-sm" v-if="rate>100" >{{$t("rate_error_paid")}}</span>
          
          <p class="pt-4" v-if="insured===true && Type_reduction ==='amount'">{{ $t("amount") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            v-if="insured===true && reduction === true && Type_reduction ==='amount'"
            min="0" 
            :max="totalAmount"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="amount"
            v-model="amount"
            class="w-full" />
          <span v-if="insured===true && reduction === true && Type_reduction ==='amount'" class="text-danger text-sm" v-show="errors.has('amount')">{{$t("amount_error")}}</span>
          <span class="text-danger text-sm" v-if="amount>totalAmount" >{{$t("amount_error_paid")}}</span>
          
          
          <p class="pt-4" v-if="insured===true && reduction === true">{{ $t("totalpaid") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-if="insured===true && reduction === true "
            readonly
            data-vv-validate-on="blur"
            name="totalpaid"
            v-model="totalpaid"
            class="w-full" />

          <!-- INSURED FALSE -->

          <p class="pt-4"  v-if="insured===false && reduction === true" >{{ $t("Type_reduction") }}<b style="color: #ff6141" >*</b> </p>         
            <v-select
            v-if="insured===false && reduction === true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="Type_reduction"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="Type_reduction"
            :options="TypeReduction">
          </v-select>
          <span  v-if="insured===false && reduction === true" class="text-danger text-sm" v-show="errors.has('Type_reduction')">{{$t("Type_reduction_error")}}</span>
           

        <p class="pt-4" v-if="insured===false && reduction === true  && Type_reduction ==='rate'">{{ $t("rate") }}%<b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            v-if="insured===false && reduction === true && Type_reduction ==='rate'"
            min="0" 
            :max="100"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="rate"
            v-model="rate"
            class="w-full" />
          <span  v-if="insured===false && reduction === true && Type_reduction ==='rate'" class="text-danger text-sm" v-show="errors.has('rate')">{{$t("rate_error")}}</span>
          <span class="text-danger text-sm" v-if="rate>100" >{{$t("rate_error_paid")}}</span>
          
          
         <p class="pt-4" v-if="insured===false && reduction === true  && Type_reduction ==='amount'">{{ $t("amount") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            v-if="insured===false && reduction === true && Type_reduction ==='amount'"
            min="0" 
            :max="totalAmount"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="amount"
            v-model="amount"
            class="w-full" />
          <span v-if="insured===false && reduction === true && Type_reduction ==='amount'" class="text-danger text-sm" v-show="errors.has('amount')">{{$t("amount_error")}}</span>
          <span class="text-danger text-sm" v-if="amount>totalAmount" >{{$t("amount_error_paid")}}</span>
          

          <p class="pt-4" v-if="insured===false && reduction === true">{{ $t("totalpaid") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-if="insured===false && reduction === true"
            readonly
            data-vv-validate-on="blur"
            name="totalpaid"
            v-model="totalpaid"
            class="w-full" />

          
          <p class="pt-4" v-if="insured===true">{{ $t("paymentType") }}<b style="color: #ff6141" >*</b> </p>
            <v-select
            v-if="insured===true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="paymentType"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="paymentType"
            :options="payement_choice">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('paymentType')">{{$t("paymentType_error")}}</span>
          
          <p v-if="paymentType==='instantPayment' &&insured===true"  class="pt-4">{{ $t("paymentMode") }}<b style="color: #ff6141" >*</b> </p>
          <v-select
          v-if="paymentType==='instantPayment' &&insured===true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="paymentMode"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="paymentMode"
            :options="payement_mode_choice">
          </v-select>
          <span v-if="paymentType==='instantPayment' &&insured===true" class="text-danger text-sm" v-show="errors.has('paymentMode')">{{$t("paymentMode_error")}}</span>
          
          <p v-if="insured===false"  class="pt-4">{{ $t("paymentMode") }}<b style="color: #ff6141" >*</b> </p>
          <v-select
          v-if="insured===false"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="paymentMode"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="paymentMode"
            :options="payement_mode_choice">
          </v-select>
          <span v-if="insured===false" class="text-danger text-sm" v-show="errors.has('paymentMode')">{{$t("paymentMode_error")}}</span>
          
          
        </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button :disabled="!(validate_rate)"  v-if="(Type_reduction=== 'rate')" class="mr-6" @click="actes_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button :disabled="!(validate_amount)"  v-if="(Type_reduction ==='amount')" class="mr-6" @click="actes_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button v-if="!(Type_reduction=== 'rate' || Type_reduction ==='amount')" class="mr-6" @click="actes_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
  
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      reduction: false,
      totalpaid:'',
      treatment:'',
      rate:'',
      amount:'',
      paymentType:'',
      paymentMode:'',
      input :[],
      Type_reduction:'',

      TypeReduction: [
        {name: 'Taux', key: 'rate'},
        {name: 'Montant', key: 'amount'}
        
      ],
      payement_choice: [
        {name: 'Tiers payant', key: 'thirdPartyPayment'},
        {name: 'paiement comptant', key: 'instantPayment'}
        
      ],
      payement_mode_choice: [
        {name: 'Espèce', key: 'Species'},
        {name: 'Chèque', key: 'check'},
        {name: 'Carte', key: 'card'}
        
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.updateTreatment = false
        this.initValues()
        this.$validator.reset()
      } else {
        this.initValues()
        this.$validator.reset()
        this.totalAmount = this.data.totalFees 
        this.name = this.data.name
        this.patient = this.data.patient.last_name
        this.insured = this.data.patient.insured
        this.treatment = this.data.id
        this.rate = this.rate
        this.amount = this.amount
        this.paymentType = this.paymentType
        this.paymentMode = this.paymentMode
        if (this.insured === false) {
          this.totalpaid = this.totalAmount
        }
        
      }
    },
    Type_reduction (value) {
      if (value === 'rate') {
        this.amount = ''
        this.totalpaid = ''
      } else if (value === 'amount') {
        this.rate = ''
        this.totalpaid = ''
      } else {
        this.rate = ''
        this.amount = ''
        this.totalpaid = ''
      }

    },
    rate (value) {
      if (value) {
        this.$http.post('total-amount/', { treatment:this.treatment, rate:value})
          .then((response) => {
            this.totalpaid = response.data.totalAmount
            this.$vs.loading.close()

          })
          .catch(() => {
            this.$vs.loading.close()
          })

      }

    },
    amount (value) {
      if (value) {
        this.$http.post('total-amount/', { treatment:this.treatment, amount:value})
          .then((response) => {
            this.totalpaid = response.data.totalAmount
            this.$vs.loading.close()

          })
          .catch(() => {
            this.$vs.loading.close()
          })

      }

    }
   
  },

  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    
    scrollbarTag () { return this.$store.getters.scrollbarTag },

    validate_rate () {
      if (
        (
          this.rate !== '' && this.rate > 0 && this.rate < 100
     
        )) return true
    },
    validate_amount () {
      if (
        (
          this.amount !== '' && this.amount > 0 && this.amount <= this.totalAmount
     
        )) return true
    }
  },
  
  methods: {
    initValues () {
      this.reduction = false
      this.totalpaid = ''
      this.totalAmount = ''
      this.name = ''
      this.patient = ''
      this.insured = ''
      this.treatment = ''
      this.rate = ''
      this.amount = ''
      this.paymentType = ''
      this.paymentMode = ''
        
    },
    actes_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    
    async submitData () {

      this.input = {
        treatment:this.treatment,
        rate:this.rate,
        amount:this.amount,
        paymentType:this.paymentType,
        paymentMode:this.paymentMode 
      }
  
      const url = 'pay-bill/'
      const methods = 'post'
      const message = {
        error: this.$t('facture_error'),
        success: this.$t('facture_save')
      }
      
  
      this.$http[methods](url, this.input)
        .then((response) => {
          window.bills_not.getAllbills_not()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'treatment') {
              libelle = this.$t('treatment')
            }
            if (item === 'rate') {
              libelle = this.$t('rate')
            }
            if (item === 'paymentType') {
              libelle = this.$t('paymentType')
            }
            if (item === 'paymentMode') {
              libelle = this.$t('paymentMode')
            }
  
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
    
          window.getPrendTaCom.error(message.error)
        })
    }
  }}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  